import React from 'react'
import logo from '../images/drinkereez_barrel_white.png'
import settings from '../resources/generalSettings.json'

export default function Footer() {
  const footerText = settings.footer_copyright + ' ' + settings.footer_version;

  return (
    <div className="footer-container bg-white">
        <img className="footer-logo img-thumbnail border-0" src={logo} alt=''/>
        <div className="footer-text">{footerText}</div>
    </div>
  )
}
