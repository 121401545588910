import React from 'react'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

Address.propTypes = {
    street: PropTypes.string,
    houseNumber: PropTypes.string,
    extraNumber: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    extraAddressLine: PropTypes.string,
    country: PropTypes.string,
    header: PropTypes.string,
    updateAddress: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    showExtraAddressLine: PropTypes.bool,
    address: PropTypes.object
};

Address.defaultProps = {
    readOnly: false,
    showExtraAddressLine: false
};

export default function Address(props) {
    const {street,houseNumber,extraHouseNumber,postalCode,city,extraAddressLine,country,header,updateAddress,readOnly,showExtraAddressLine,address} = props;

    const addrStreet = address ? address.street : street;
    const addrHouseNumber = address ? address.houseNumber : houseNumber;
    const addrExtraHouseNumber = address ? address.extraHouseNumber : extraHouseNumber;
    const addrPostalCode = address ? address.postalCode : postalCode;
    const addrCity = address ? address.city : city;
    const addrExtraAddressLine = address ? address.extraAddressLine : extraAddressLine;
    const addrCountry = address ? address.country : country;

    function changeHandler(e) {
        const { name, value } = e;
        updateAddress({ [name]: value });
    }

    return (
        <div className="Address mb-5">
            <FormLineParagHeader label={header}/>
            <div className='row'>
                <FormLineInput col={6} label="label.street" name="street" value={addrStreet} readOnly={readOnly} onChange={changeHandler} required={true}/>
                <FormLineInput col={3} label="label.number" name="houseNumber" value={addrHouseNumber} readOnly={readOnly} onChange={changeHandler} required={true}/>
                <FormLineInput col={3} label="label.extraNumber" name="extraHouseNumber" value={addrExtraHouseNumber} readOnly={readOnly} onChange={changeHandler} donotShowEmpty={true}/>
            </div>
        {showExtraAddressLine && (
            <div className='row'>
                <FormLineInput label="label.extraAddressLine" name="extraAddressLine" value={addrExtraAddressLine} readOnly={readOnly} onChange={changeHandler}/>
            </div>
        )}
            <div className='row'>
                <FormLineInput col={2} label="label.postalCode" name="postalCode" value={addrPostalCode} readOnly={readOnly} onChange={changeHandler} required={true}/>
                <FormLineInput col={4} label="label.city" name="city" value={addrCity} readOnly={readOnly} onChange={changeHandler} required={true}/>
                <FormLineSelect col={3} list={resources.addressCountries} name="country" label="label.country" value={addrCountry} readOnly={readOnly}  onChange={changeHandler} required={true} order={true}/>
            </div>
        </div>
    )
}
