import React,{useCallback} from 'react'
import FormLineInput from './FormLineInputV2';
import FormLineSelect from './FormLineSelectV2';
import FormLineParagHeader from './FormLineParagHeader';
import { log } from '../../../Log';
import FormButton from './FormButton';
import FormLineErrorMessage from './FormLineErrorMessage';

export default function FormCard ({header,rows,fields}) {
    log.debug("FormCard");
    log.debug(rows);

    const addHeader = header && header!=="" ? <FormLineParagHeader label={header}/> : null;

    const addField = useCallback((field) => {
        log.debug("field");
        log.debug(field);

        if (field.dontShow) 
            return

        return field.fType === "input" ? <FormLineInput {...field} /> :
               field.fType === "select" ? <FormLineSelect {...field} />: 
               field.fType === "button" ? <FormButton {...field} /> :
               field.fType === "title" ? <FormLineParagHeader {...field} /> :
               field.fType === "error" ? <FormLineErrorMessage {...field} /> : 
               null;
    }, []);

    const addRow = useCallback((row) => {
        log.debug("row");
        log.debug(row);
        return (
            <div className="row">
                {row.map(field => addField(field))}
            </div>
        );
    }, [addField]);

    const addContent =  useCallback(() => {
        if (rows) {
            log.debug("in rows");
            return rows.map(row => addRow(row));
        } if (fields)
            return fields.map(field => addField(field));
        return;
    }, [rows,fields,addRow,addField]);

    return (
        <div className="FormCard"> 
            {addHeader}
            {addContent()}
        </div>
    ); 
}