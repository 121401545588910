import React from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
//import { log } from '../../Log';
import PropTypes from 'prop-types';
import FormCard from '../layout/forms/FormCard';

ShowShipmentItem.propTypes = {
    header: PropTypes.string,
    //id: PropTypes.string,
    name: PropTypes.string,
    cnCode: PropTypes.string,
    alcoholPercentage: PropTypes.number,
    volumePerUnit: PropTypes.number,
    numberOfUnits: PropTypes.number,
    totalVolume: PropTypes.number,
    numberOfCollis: PropTypes.number,
    updateItem: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    item: PropTypes.object
};

ShowShipmentItem.defaultProps = {
    editMode: false
};

export default function ShowShipmentItem({
    header,
    updateItem,
    editMode = false,
    item = {},
    //id,
    name,
    cnCode,
    alcoholPercentage,
    volumePerUnit,
    numberOfUnits,
    totalVolume,
    numberOfCollis
}) {
    const itemName = item.name || name || "";
    const itemCnCode = item.cnCode || cnCode || "";
    const itemAlcoholPercentage = item.alcoholPercentage || alcoholPercentage || "";
    const itemVolumePerUnit = item.volumePerUnit || volumePerUnit || "";
    const itemNumberOfUnits = item.numberOfUnits || numberOfUnits || "";
    const itemTotalVolume = item.totalVolume || totalVolume || "";
    const itemNumberOfCollis = item.numberOfCollis || numberOfCollis || "";
    //const itemId = item.id || id;

    const fields = [
        {fType: "input", label: "label.itemName", name: "name", value: itemName, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.cnCode", name: "cnCode", value: itemCnCode, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.alcoholPercentage", name: "alcoholPercentage", value: itemAlcoholPercentage, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.volumePerUnit", name: "volumePerUnit", value: itemVolumePerUnit, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.numberOfUnits", name: "numberOfUnits", value: itemNumberOfUnits, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.totalVolume", name: "totalVolume", value: itemTotalVolume, updateObject: updateItem, readOnly: !editMode, required:true},
        {fType: "input", label: "label.numberOfCollis", name: "numberOfCollis", value: itemNumberOfCollis, updateObject: updateItem, readOnly: !editMode, required:true},
    ];

    return (
        <div className="ItemData mb-5">
            <FormCard fields={fields}/>
        </div>
    );
/*
    return (
        <div className="ItemData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput
                    col={6}
                    label="label.itemName"
                    name="name"
                    readOnly={!editMode}
                    value={itemName}
                    updateObject={updateItem}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.cnCode"
                    name="cnCode"
                    readOnly={!editMode}
                    value={itemCnCode}
                    updateObject={updateItem}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.alcoholPercentage"
                    name="alcoholPercentage"
                    readOnly={!editMode}
                    value={itemAlcoholPercentage}
                    updateObject={updateItem}
                    type="decNumber"
                    required
                />
            </div>
            <div className='row'>
                <FormLineInput
                    col={3}
                    label="label.volumePerUnit"
                    name="volumePerUnit"
                    readOnly={!editMode}
                    value={itemVolumePerUnit}
                    updateObject={updateItem}
                    type="decNumber"
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.numberOfUnits"
                    name="numberOfUnits"
                    readOnly={!editMode}
                    value={itemNumberOfUnits}
                    updateObject={updateItem}
                    type="number"
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.totalVolume"
                    name="totalVolume"
                    readOnly={!editMode}
                    value={itemTotalVolume}
                    updateObject={updateItem}
                    type="decNumber"
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.numberOfCollis"
                    name="numberOfCollis"
                    readOnly={!editMode}
                    value={itemNumberOfCollis}
                    updateObject={updateItem}
                    type="number"
                    required
                />
            </div>
        </div>
    );*/
}