import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
//import seedTest from '../../resources/test/seedTest.json';

export function CallVerifyExcise (exciseNumber,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallVerifyExcise");

    const body = { 
        exciseNumbers : [exciseNumber]
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        const response = {};
        responseFunc (response);
    }
    else {
        CallBackend ("seed",body, response => {
            responseFunc(response);
        });
    }
}