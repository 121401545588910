import React, { useCallback } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { log } from '../../../Log';

export default function FormNavBar ({items,path,client,title}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    log.debug("items");
    log.debug(items);

    const navigateItem = useCallback ((item) => {
        // Added the "!" because explicitly putting "" does not work, so "!" replaces the explicit "" to overrule the general path
        const itemPath = item.path ? (item.path === "!" ? "" : item.path ) : path ? path : ""; 
        const itemHref = item.href && item.href !== "" ? item.href : itemPath + "/" + item.id;
        navigate(itemHref);
    })

    const optionClicked = useCallback((e) => {
        const option = e.target.id;

        log.debug("optionClicked : " + option);
        log.debug(items);

        // Loop through main items and their dropdownItems
        const found = items.some(item => {
            if (item.id === option) {
                log.debug("navigate to item");
                log.debug(item);
                navigateItem(item);
                return;
            }
        });
    }, [items, navigateItem]);
    

    function clientHasRole(services) {
        // Check if roles is null or empty, or if client.roles is undefined or not an array
        if (!services || services.length === 0)
            return true;
        if (!client || !client.services || client.services.length === 0)
            return false;
        return services.some(service => client.services.includes(service));
    }

    const renderNavBarItem = useCallback((item) => {
        if (!clientHasRole(item.services)) 
            return null;

        const itemLabel = item.label && item.label !== "" ? t(item.label) : t("tab.navbar_" + item.id);

        return (
            <li key={item.id}>
                <a className="dropdown-item" href="#nogo" id={item.id} onClick={optionClicked}>
                    {itemLabel}
                </a>
            </li>
        );
    }, [items, optionClicked]);

    const renderNavBarItems = useCallback(() => {
        if (!items || items.length === 0)
            return null;
        return items.map(renderNavBarItem);
    }, [items, renderNavBarItem]);

    /*const addTitle = useCallback (() => {
        if (!title)
            return null;

        return (
            <li className="nav-item">
                <div id="title" className="nav-link link-dark" aria-expanded="false" href="#nogo">
                    {title}
                </div>
            </li>
        );
    });*/

    return (
        <ul className='nav'>
            <li className="nav-item dropdown">
                <a id="menu" className="nav-link link-dark nav-link-title" data-bs-toggle="dropdown" role="button" aria-expanded="false" href="#nogo">
                    <span className='material-symbols-outlined'>menu</span> {title}
                </a>
                <ul className="dropdown-menu">
                    {renderNavBarItems()}
                </ul>
            </li>
        </ul>
    );


   /*return (
        <ul className='nav'>
            <li className="nav-item dropdown">
                <a id="menu" className="nav-link link-dark" data-bs-toggle="dropdown" role="button" aria-expanded="false" href="#nogo">
                    {t("tab.navbar_menu")}
                </a>
                <ul className="dropdown-menu">
                    {renderNavBarItems()}
                </ul>
            </li>
            {addTitle()}
        </ul>
    );*/
}