import React, {useState} from 'react'
//import Address from '../General/Address'
import ContactPerson from '../General/ContactPerson'
//import CompanyGeneralData from '../General/CompanyGeneralData'
import NewPassword from '../General/NewPassword'
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage'
import { validEmail, validPassword } from '../Regex';
import { CallRegistrateClient }  from './CallClient';
import FormButton from '../layout/forms/FormButton'
import PropTypes from 'prop-types';
import GetCompany from '../General/GetCompany';
import { log } from '../../Log';

RegisterClient.propTypes = {
    clientRegistered: PropTypes.func.isRequired
};

export default function RegisterClient({clientRegistered}) {
    const [contactPerson,setContactPerson] = useState ({});
    const [contactAddress,setContactAddress] = useState ({});
    const [company,setCompany] = useState ({});
    const [newPasswords,setNewPasswords] = useState ({});
    const [error, setError] = useState({message: '',code: '' ,errorJSON: {}});

    function submitHandler (e) {
        setError({message: '',code: '' ,errorJSON: {}});
        e.preventDefault();
        if (!validEmail.test(contactPerson.eMail)) {
            setError({code: 'incorrect_regex_email',errorJSON: {}});
        //} else if (!validPassword.test(newPasswords.newPassword)) {
        //    setError({errorMessage: 'incorrect_regex_password',errorJSON: {}});
        } else if (newPasswords.newPassword !== newPasswords.confirmedPassword) {
            setError({code: 'password_not_equal',errorJSON: {}});
        } else {
            CallRegistrateClient(contactPerson,company,contactAddress,newPasswords,response => {
                if (response.err || response.errCode) {
                    setError({message: response.err,code: response.errCode, errorJSON: {contactPerson,company,contactAddress,newPasswords}});
                } else {
                    clientRegistered();
                }
            });
        }
    }

    function resetHandler () {
        setContactPerson({});
        setCompany({});
        setNewPasswords({});
        setError({message: '',code: '' ,errorJSON: {}});
    }

    function updateContactPerson (change) {
        setContactPerson({...contactPerson,...change});
    }

    function updateAddress (change) {
        //setCompany({...company,mainAddress:{...company.mainAddress,...change}});
        setContactAddress({...contactAddress,...change});
    }

    /*function updateCompanyGeneralData (change) {
        setCompany({...company,...change});
    }*/

    function updateCompany (comp) {
        log.debug('updateCompany');
        log.debug(comp);
        setCompany(comp);
    }

    function updateNewPassword (change) {
        setNewPasswords({...newPasswords,...change});
    }

    return (
        <form>
            <GetCompany 
                company={company} 
                address={contactAddress}
                header="title.company" 
                updateCompany={updateCompany} 
                updateSecundairyAddress={updateAddress} 
                editMode={true}
                editVATNumber={true}
            />
            <ContactPerson 
                {...contactPerson} 
                header="title.contactPerson" 
                updateContactPerson={updateContactPerson}
            />
            <NewPassword 
                {...newPasswords} 
                header="title.password" 
                updatePassword={updateNewPassword}  
                askOldPassword={false}
            />
            <FormLineErrorMessage 
                error={error}
            />
            <FormButton 
                type="submit" 
                onClick={submitHandler} 
                label='button.submit'
            />
            <FormButton 
                type="reset" 
                onClick={resetHandler} 
                label='button.reset' 
                level='secondary'
            />
        </form> 
    )
}

//<CompanyGeneralData {...company} header="title.company" updateCompanyGeneralData={updateCompanyGeneralData}/>
//<Address {...company.mainAddress} header="title.companyAddress" updateAddress={updateAddress}/>