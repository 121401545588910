import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginScreen from './User/LoginScreen';
import RegisterClient from './Client/RegisterClientV2';
import ActivateUser from './User/ActivateUser';
import AdminPortal from './Administrator/AdminPortal'
import Title from './Title';
import UserPortal from './User/UserPortal';
import { log } from '../Log';
import Footer from './Footer';
import ReceiverB2BPortal from './ReceiverB2B/ReceiverB2BPortal';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import Logout from './Logout';

export const AppContext = React.createContext();

export default function Drinkereez() {
    const [user,setUser] = useState();
    const [login,setLogin] = useState();
    const [producer,setProducer] = useState();
    const [client,setClient] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        log.debug("in Drinkereez");
        log.debug({login:login});
        log.debug({user:user});
        log.debug({producer:producer});
        log.debug({client:client});

        // Retrieve cookies on component mount
        const userFromCookie = Cookies.get('user');
        const loginFromCookie = Cookies.get('login');
        const clientFromCookie = Cookies.get('client');

        if (userFromCookie) setUser(JSON.parse(userFromCookie));
        if (loginFromCookie) setLogin(JSON.parse(loginFromCookie));
        if (clientFromCookie) setClient(JSON.parse(clientFromCookie));
    }, []);

    // Update cookies whenever user, login, or client state changes
    useEffect(() => {
        if (user) {
            Cookies.set('user', JSON.stringify(user), { expires: 7 });
            if (user.userType === 'USER_TYPE_ADMIN') {
                navigate('/admin-portal/home');
            } else {
                navigate('/portal/home');
            }
        } else {
            Cookies.remove('user');
        }
    }, [user]);

    useEffect(() => {
        if (login) {
            Cookies.set('login', JSON.stringify(login), { expires: 7 });
        } else {
            Cookies.remove('login');
        }
    }, [login]);

    useEffect(() => {
        if (client) {
            Cookies.set('client', JSON.stringify(client), { expires: 7 });
        } else {
            Cookies.remove('client');
        }
    }, [client]);

    const clientName = client && client.company ? client.company.name : ""; 
    const userName = user && user.person ? user.person.firstName + " " + user.person.lastName : "";

    const loginNavBarItems = {
        items : [
            {id : "register"},
            {id : "login"},
    ]}

    const adminPortalNavBarItems = {
        path : "/admin-portal",
        items: [
            { id: 'orders'},
            { id: 'producers'},
            { id: 'product', label: 'tab.navbar_products'},
            { id: 'excises'},
            { id: 'xml', label: 'tab.navbar_xmls'},
            { id: 'users'},
            { id: 'clients'},
            { id: 'clientServices'},
            { id: 'calculator'},
            { id: 'converter'},
            { id: 'userData', label: userName }, 
            { id: 'natCode',}, 
            { id: 'tarNatCode'}, 
            { id: 'natEUCode'}, 
            { id: 'uploadFile'}, 
            { id: 'vies'},
            { id: 'seed'},
            { id: 'logout', path : "!"}  // set path to "!" to overrule the general path to set it ""
    ]}

    const clientPortalNavBarItems = {
        path : "/portal",
        items : [
            { id: 'orders', services: ['CLIENT_SERVICE_IMPORT'] },
            { id: 'products', services: ['CLIENT_SERVICE_IMPORT'] },
            { id: 'shipments'},
            { id: 'calculator', services: ['CLIENT_SERVICE_IMPORT'] },
            { id: 'producerData', services: ['admin'] },
            { id: 'servicesList' }, 
            { id: 'clientData', label: clientName }, 
            { id: 'userData', label: userName },
            { id: 'logout', path : "!"}
    ]};

    const getNavBarConf = useCallback(() => {
        const navBarConf = !user ? loginNavBarItems : 
                user.userType === 'USER_TYPE_CLIENTADMIN' ? clientPortalNavBarItems :
                user.userType === 'USER_TYPE_PRODADMIN' ? clientPortalNavBarItems :
                user.userType === 'USER_TYPE_PRODUSER' ? clientPortalNavBarItems :
                user.userType === 'USER_TYPE_ADMIN' ? adminPortalNavBarItems :
                {items : [{ id: 'logout'}]};
        return navBarConf;
    },[user]);

    /*const clientPortalNavBarItems = [
        { id: 'orders', path : "/portal" , services: ['CLIENT_SERVICE_IMPORT'] },
        { id: 'products', path : "/portal", services: ['CLIENT_SERVICE_IMPORT'] },
        { id: 'shipments', path : "/portal"},
        { id: 'tools', 
                    dropdownItems: [
                        { id: 'calculator', services: ['CLIENT_SERVICE_IMPORT'] },
                        { id: 'producerData', services: ['admin'] },
                        { id: 'servicesList', path : "/portal" }, 
                        { id: 'clientData', path : "/portal", label: clientName }, 
                        { id: 'userData', path : "/portal", label: userName },
                        { id: 'logout'}
                    ]}
    ];*/

    /*const navBarItems = !user ? loginNavBarItems : 
                        user.userType === 'USER_TYPE_PRODADMIN' ? clientPortalNavBarItems :
                        user.userType === 'USER_TYPE_ADMIN' ? adminPortalNavBarItems :
                        [];*/
/*
    // Determine the navigation bar based on user state
    let NavBarComponent = null;
    if (!user) {
        const items = [
            {id : "register"},
            {id : "login"},
        ]
        //NavBarComponent = <FormNavBar items={items}/>
        NavBarComponent = <LoginNavBar navOptionClicked={navOptionClicked} />;
    } else if (user.userType === 'USER_TYPE_ADMIN') {
        NavBarComponent = <AdminPortalNavBar navOptionClicked={navOptionClicked} />;
    } else if ((user.userType === 'USER_TYPE_PRODADMIN') || (user.userType === 'USER_TYPE_CLIENTADMIN')) {
        NavBarComponent = <ClientPortalNavBar navOptionClicked={navOptionClicked} />;
    } else if (user.userType === 'USER_TYPE_RECADMIN') {
        NavBarComponent = <ReceiverB2BNavBar navOptionClicked={navOptionClicked} />;
    }*/

    return (
        <AppContext.Provider value={{ user, setUser, login, setLogin, producer, setProducer, client, setClient }}>
            <main className="container position-relative bg-white">
                <div className='sticky-top bg-white'>
                    <Title navBarConf={getNavBarConf()}/>
                </div>
                <Routes>
                    <Route path="/activate/:userId/:activationCode" element={<ActivateUser />} />
                    <Route path="/login" element={<LoginScreen/>} />
                    <Route path="/logout" element={<Logout/>} />
                    <Route path="/register" element={<RegisterClient/>} />
                    <Route path="/portal/*" element={<ProtectedRoute allowedRoles={['USER_TYPE_PRODADMIN', 'USER_TYPE_CLIENTADMIN']} />}>
                        <Route path=":screenToShow" element={<UserPortal />} />
                    </Route>
                    <Route path="/admin-portal/*" element={<ProtectedRoute allowedRoles={['USER_TYPE_ADMIN']} />}>
                        <Route path=":screenToShow" element={<AdminPortal />} />
                    </Route>
                    <Route path="/receiver-portal" element={<ReceiverB2BPortal screenToShow={location.pathname.substring(1)} />} />
                </Routes>
                <Footer />
            </main>
        </AppContext.Provider>
    );
}

//<div className='' style={{ height: '250px' }}></div>
