import React from 'react'
import cookies from 'js-cookie';
import 'flag-icon-css/css/flag-icons.min.css'
import { useTranslation } from "react-i18next";
import FormNavBar from './layout/forms/FormNavBar';
import FormLanguageSelection from './layout/forms/FormLanguageSelection';
import { log } from '../Log';
import FormLineInput from './layout/forms/FormLineInput';

export default function Title({navBarConf}) {
    const {t} = useTranslation();
    //const menuClass = `dropdown-menu${isOpen ? " show bg-light" : " bg-light"}`;
    const currentLanguageCode = cookies.get('i18next') || "en";
    const titleName = t('title.drinkereez') + " " + process.env.REACT_APP_TEST_TEXT;

    return (
        <div className="Title row">
            <div className='col-9'>
                <FormNavBar {...navBarConf} title={titleName}/>
            </div>
            <div className="col-3 d-flex justify-content-end">
                <FormLanguageSelection currentLanguageCode={currentLanguageCode}/>
            </div>
        </div>
    )

    /*return (
        <div className="Title row">
        <div className='col-1'>
            <FormNavBar items={navBarItems}/>
        </div>
        <div className='col-10 justify-content-center'>
            <h1 className="text-black text-center">{titleName}</h1>
        </div>
        <div className="col-1 d-flex justify-content-end">
            <FormLanguageSelection currentLanguageCode={currentLanguageCode}/>
        </div>
    </div>
    )*/
}
