import React from 'react';
import { useParams } from 'react-router-dom';
import Calculator from '../Calculator/Calculator';
import UserData from '../User/UserData';
//import {log} from '../../Log';
import ShowUsers from '../User/ShowUsers';
import ShowProducer from '../Producer/ProducerPage';
import ProductsPage from '../Product/ProductsPage';
import OrderPage from '../Order/OrderPage';
import ExcisePage from '../Excise/ExcisePage';
import XmlPage from '../Xml/XmlPage';
import NationalCode from '../NationalCode/NationalCodePage';
import Converter from '../Calculator/Converter';
import TestUploadFile from '../UploadFile/TestUploadFile';
import ClientServicesPageAdmin from '../Client/ClientServicesPageAdmin';
import SearchCompanyVies from '../EUService/SearchCompanyVies';
import VerifyExciseSeed from '../EUService/VerifyExciseSeed';

export default function AdminPortal() {
    const {screenToShow} = useParams();

    // Determine the main content based on user state and screenToShow
    let mainContent;
    switch (screenToShow) {
        case "calculator": mainContent = <Calculator />; break;
        case "userData": mainContent = <UserData />; break;
        case "users": mainContent = <ShowUsers />; break;
        case "clientServices": mainContent = <ClientServicesPageAdmin />; break;
        case "producers": mainContent = <ShowProducer/>; break;
        case "products": mainContent = <ProductsPage />; break;
        case "orders": mainContent = <OrderPage />; break;
        case "excises": mainContent = <ExcisePage />; break;
        case "xml": mainContent = <XmlPage />; break;
        case "converter": mainContent = <Converter />; break;
        case "natCode": mainContent = <NationalCode screen="natCodeList" />; break;
        case "tarNatCode": mainContent = <NationalCode screen="tarifList" />; break;
        case "natEUCode": mainContent = <NationalCode screen="linkNatCodeEUCodeList" />; break;
        case "uploadFile": mainContent = <TestUploadFile />; break;
        case "vies": mainContent = <SearchCompanyVies />; break;
        case "seed": mainContent = <VerifyExciseSeed />; break;
        case "home": mainContent = null; break;
        default: mainContent = null;
    }

    return (
        <div className="adminPortal container">
            {mainContent}
        </div>
    )
}