import React, { useContext,useState, useCallback } from 'react';
import { AppContext } from '../Drinkereez';
//import FormLineFileInput from '../layout/forms/FormLineFileInput';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import resources from '../../resources/lists/dropdownlist.json';
import ShowTransport from '../General/ShowTransport';
import { log } from '../../Log';
import ShowCompany from '../General/ShowCompany';
import ShowShipmentItems from './ShowShipmentItems';
import ShowShipmentItem from './ShowShipmentItem';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import { CallSaveShipment } from './CallShipment';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';

ShowShipment.propTypes = {
    shipment: PropTypes.object,
    stopShowShipment: PropTypes.func
};

export default function ShowShipment({ shipment: initialShipment, stopShowShipment }) {
    const { login } = useContext(AppContext);
    const [shipment, setShipment] = useState(initialShipment);
    const [itemSelected, setItemSelected] = useState(null);
    const [modifyItem, setModifyItem] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({message: '',code: '' ,errorJSON: {}});
    const [successMessage, setSuccessMessage] = useState('');

    function clearMessages() {
        setError({message: '',code: '' ,errorJSON: {}});
        setSuccessMessage('');
    }

    const changeHandler = useCallback((e) => {
        const { name, value } = e.target;
        setShipment((prevShipment) => ({ ...prevShipment, [name]: value }));
    }, []);

    const updateConsignor = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,consignor: { ...prevShipment.consignor, ...change },}));
    }, []);

    const updateTransport = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,transport: { ...prevShipment.transport, ...change },}));
    }, []);

    const editHandler = useCallback(() => {
        clearMessages();
        setEditMode(true);
    }, []);

    const saveHandler = useCallback(() => {
        CallSaveShipment(login,shipment,response => {
            log.debug(response);
            if (response.err || response.errCode) {
                setError({message: response.err,code: response.errCode, errorJSON: {shipment}});
            } else {
                setSuccessMessage("Saved shipment successfully");
                setEditMode(false);
            }
        });
    }, []);

    const cancelHandler = useCallback(() => {
        stopShowShipment();
    }, []);

    function editOrSaveButton() {
        if (editMode) {
            return (
                <FormButton
                    onClick={saveHandler}
                    level='primary'
                    label='button.save'
                />
            )
        } else {
            return (
                <FormButton
                    onClick={editHandler}
                    level='primary'
                    label='button.edit'
                />
            )
        }
    }

    const updateItem = useCallback((e) => {
        setItemSelected((prevItem) => ({...prevItem, ...e}));
    }, []);

    const cancelItemHandler = useCallback(() => {
        setItemSelected(null);
        setModifyItem(false);
    }, []);

    const editItemHandler = useCallback(() => {
        setModifyItem(true);
    }, []);

    const saveItemHandler = useCallback(() => {
        setShipment((prevShipment) => ({...prevShipment, items:[...prevShipment.items, itemSelected]}));
        setModifyItem(false);
    }, []);

    function editOrSaveButtonItem() {
        if (modifyItem) {
            return (
                <FormButton
                    onClick={saveItemHandler}
                    level='primary'
                    label='button.save'
                />
            )
        } else {
            return (
                <FormButton
                    onClick={editItemHandler}
                    level='primary'
                    label='button.edit'
                />
            )
        }
    }

    function showItem() {
        if (itemSelected) {
            return (
                <>
                    <ShowShipmentItem
                        header="title.item"
                        item={itemSelected}
                        updateItem={updateItem}
                        editMode={modifyItem}
                    />
                    {editOrSaveButtonItem()}
                    <FormButton 
                        onClick={cancelItemHandler} 
                        level='secondary' 
                        label='button.cancel'
                    />
                </>
            )
        }
    }

    return (
        <div className="ShowShipment">
            <div className='row'>
                <FormLineInput
                    col={3}
                    readOnly
                    type="text"
                    label="label.registrationDate"
                    name="registrationDate"
                    value={shipment.registrationDate}
                    onChange={changeHandler}
                    required
                />
                <FormLineSelect
                    col={3}
                    readOnly
                    type="text"
                    label="label.state"
                    name="state"
                    value={shipment.state}
                    list={resources.shipmentStates}
                    onChange={changeHandler}
                    required
                />
            </div>
            <ShowCompany
                header="title.consignee"
                company={shipment.consignee}
                editMode={false}
            />
            <ShowCompany
                header="title.consignor"
                company={shipment.consignor}
                updateCompany={updateConsignor}
                editMode={editMode}
            />
            <ShowTransport
                header="title.transport"
                transport={shipment.transport}
                updateTransport={updateTransport}
                editMode={editMode}
            />
            <ShowShipmentItems
                items={shipment.items}
                itemSelected={setItemSelected}
            />
            {showItem()}
            <FormLineErrorMessage 
                error={error}
            />
            <FormLineSuccessMessage
                message={successMessage}
            />
            {editOrSaveButton()}
            <FormButton 
                onClick={cancelHandler} 
                level='secondary' 
                label='button.cancel'
            />
        </div>
    );
}
