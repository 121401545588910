import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import clientTest from '../../resources/test/clientTest.json';

export function CallRegistrateClient (contactPerson,company,contactAddress,newPasswords,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallRegistrateClient");

    const body = { 
        req : {action: 1},
        country: company.mainAddress.country,
        company: company,
        contactAddress: contactAddress,
        mainContact: {
            username: contactPerson.eMail,
            password: newPasswords.newPassword,
            person: contactPerson
        }
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("Test");
    } else {
        CallBackend ("client",body, response => {
            responseFunc(response);
        });
    }   
}

export function CallGetClientFromUser (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallGetClientFromUser");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (clientTest.client2);
    }
    else {
        const body = { 
            req : {...login,action: 2}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("client",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveClient (login,client,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveClient");
    
    const body = { 
        req : {...login,action: 5},
        ...client
    }

    log.debug(body);
    log.debug("post");
    
    if (testMode) {
        log.debug("Test");
        responseFunc(client);
    } else {
        CallBackend ("client",body, response => {
            responseFunc(response);
        });
    } 
}

export function CallGetClientServicesFromUser (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallGetClientServicesFromUser");

    const body = {
        req : {...login,action: 7}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc(clientTest.clientServices2);
        //responseFunc ({err : "error.server_return_error"});
        //responseFunc (clientTest.clientServices1);
    }
    else {
        CallBackend ("client",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveClientService (login,clientService,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveClientService");

    const body = { 
        req : {...login,action: 6},
        ...clientService
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("Test");
        responseFunc(body);
    } else {
       CallBackend ("client",body, response => {
            responseFunc(response);
        });
    } 
}

export function CallGetAllClientServices (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallGetAllClientServices");

    const body = { 
        req : {...login,action: 8}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc(clientTest.clientServices2);
    } else {
       CallBackend ("client",body, response => {
            responseFunc(response);
        });
    } 
}

export function CallAcceptDenyClientServices (login,accept,id,comment,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallGetAllClientServices");

    const newState = accept ? "DRINKEREEZ_SERVICE_STATE_ACTIVE" : "DRINKEREEZ_SERVICE_STATE_DENIED";

    const body = { 
        req : {...login,action: 9},
        accept : accept,
        id : id,
        state : newState,
        comment : comment
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc(clientTest.clientServices2);
    } else {
       CallBackend ("client",body, response => {
            responseFunc(response);
        });
    } 
}