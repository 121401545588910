import React from 'react';
import PropTypes from 'prop-types';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import resources from '../../resources/lists/dropdownlist.json';
import FormLineFileInputV2 from '../layout/forms/FormLineFileInputV2';
import FormCard from '../layout/forms/FormCard';

ShowInvoice.propTypes = {
    header: PropTypes.string,
    updateInvoice: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    invoice: PropTypes.object,
    setError: PropTypes.func.isRequired
};

ShowInvoice.defaultProps = {
    editMode: false,
    invoice: {}
};

export default function ShowInvoice ({header,updateInvoice,editMode,invoice,setError}) {

    const fields = [
        {fType: "input", label: "label.number", name: "number", value: invoice.number, updateObject: updateInvoice,readOnly:!editMode, required: true}, 
        {fType: "input", label: "label.date", name: "date", value: invoice.date, updateObject: updateInvoice,readOnly:!editMode, required: true}, 
        {fType: "input", label: "label.totalAmount", name: "totalAmount", value: invoice.totalAmount, updateObject: updateInvoice,readOnly:!editMode, required: true}, 
        {fType: "select", label: "label.currency", list: resources.currencies, order: true, name: "currency", value: invoice.currency, updateObject: updateInvoice, readOnly: !editMode}, 
    ];

    return (
        <div className="TransportData mb-5">
            <FormCard header={header} centerHeader={true} fields={fields}/>
            <FormLineFileInputV2 
                label="label.document" 
                readOnly={!editMode}
                updateFile={updateInvoice}
                file={invoice.document}
                setError={setError}
            />
        </div>
    );

/*    return (
        <div className="TransportData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput
                    col={3}
                    label="label.number"
                    name="number"
                    readOnly={!editMode}
                    value={invoice.number}
                    updateObject={updateInvoice}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.date"
                    name="date"
                    readOnly={!editMode}
                    value={invoice.date}
                    updateObject={updateInvoice}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.totalAmount"
                    name="totalAmount"
                    readOnly={!editMode}
                    value={invoice.totalAmount}
                    updateObject={updateInvoice}
                    type="decNumber"
                    required
                />
                <FormLineSelect
                    col={3}
                    list={resources.currencies}
                    label="label.currency"
                    name="currency"
                    readOnly={!editMode}
                    value={invoice.currency}
                    updateObject={updateInvoice}
                    required
                />
            </div>
            <FormLineFileInputV2 
                label="label.document" 
                readOnly={!editMode}
                updateFile={updateInvoice}
                file={invoice.document}
                setError={setError}
            />
        </div>
    )*/
}
