import React,{useState} from 'react';
//import { AppContext } from '../Drinkereez';
import ShowShipments from './ShowShipments';
import ShowShipment from './ShowShipment';

export default function ShipmentPage() {
    const [shipment,setShipment] = useState(); // eslint-disable-line no-unused-vars
    const [showShipmentList, setShowShipmentList] = useState(true);
    const [showShipment,setShowShipment] = useState(false); // eslint-disable-line no-unused-vars

    function shipmentSelected (shipment) {
        setShowShipmentList(false);
        setShowShipment(true);
        setShipment(shipment);       
    }

    function stopShowShipment () {
        setShowShipmentList(true);
        setShowShipment(false);
    }

    function shipmentSaved () {  // eslint-disable-line no-unused-vars
        //getAllProducers();
        stopShowShipment();
    }

    return (
        <div className="ShipmentPage">
            {
                showShipmentList && <ShowShipments shipmentSelected={shipmentSelected}/>
            }
            {
                showShipment && <ShowShipment shipment={shipment} shipmentSaved={shipmentSaved} stopShowShipment={stopShowShipment}/>
            }
        </div>
    )
}
