import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import { log } from '../../Log';
import { CallGetCompany } from '../EUService/CallVies';
import { isEUCountry, formatVATNumber } from '../GeneralFunc'
import resources from '../../resources/lists/dropdownlist.json'
import Address from './Address';
import FormCard from '../layout/forms/FormCard';

GetCompany.propTypes = {
    header: PropTypes.string,
    updateCompany: PropTypes.func.isRequired,
    updateSecundairyAddress: PropTypes.func,
    updateCompanyAddress: PropTypes.func,
    editMode: PropTypes.bool,
    company: PropTypes.object,
    address: PropTypes.object,
    addressHeader: PropTypes.string,
    addressButton: PropTypes.string,
    editVATNumber: PropTypes.bool,
    otherAddressHeader: PropTypes.string,
    allowNonEU: PropTypes.bool
};

GetCompany.defaultProps = {
    editMode: false,
    editVATNumber: false,
    allowNonEU: false
};

export default function GetCompany({
    header,
    addressHeader,
    addressButton,
    updateCompany,
    updateSecundairyAddress,
    updateCompanyAddress,
    editMode,
    editVATNumber,
    allowChangeNonEU,
    otherAddressHeader,
    company = {},
    address = {}
}) {
    const [error,setError] = useState({message: '',code: '', errorJSON: {}});
    const [showSecundairyAddress, setShowSecundairyAddress] = useState(address && Object.keys(address).length > 0 ? true : false);
    const otherAddress = addressButton ? addressButton : "button.addContactAddress";
    const otherAddrHeader = otherAddressHeader ? otherAddressHeader : "title.contactAddress";
    const noHeader = !header || header==="";

    const searchHandler = useCallback(() => {
        log.debug("searchHandler");
        log.debug(company);
        if (!company?.mainAddress?.country || !company.VATNumber)
            return
        const VATNumber = formatVATNumber(company?.mainAddress?.country,company.VATNumber);
        CallGetCompany('',VATNumber,response => {
            log.debug(response);
            if (response.err || response.errCode) {
                setError({message: response.err,code: response.errCode, errorJSON: {VATNumber}});
            } else if (!response.valid) {
                setError({message: "number invalid",code: "INVALID", errorJSON: {VATNumber}});
            } else {
                setError({message: '',code: '' ,errorJSON: {}});
                const retComp = {
                                    VATNumber: response.countryCode + "" + response.vatNumber,
                                    name:response.name,
                                    nonEU: false,
                                    mainAddress: {
                                        street: response.officialAddress?.street,
                                        postalCode: response.officialAddress?.postalCode,
                                        city: response.officialAddress?.city,
                                        country: response.countryCode
                                    }
                                };
                updateCompany(retComp);
            }
        }); 
    }, [company,updateCompany]);

    const changeHandler = useCallback((e) => {
        updateCompany({VATNumber: e.value});
    }, [updateCompany]);

    const showOtherAddressHandler = () => {
        if (showSecundairyAddress) {
            updateSecundairyAddress(null);
        } else if (!address || Object.keys(address).length <= 0) {
            updateSecundairyAddress({country: company.mainAddress.country});
        }
        setShowSecundairyAddress(!showSecundairyAddress);
    }

    const checkForSecundairyAddress = () => {
        if (updateSecundairyAddress && company.mainAddress && Object.keys(company.mainAddress).length > 0) {
            if (showSecundairyAddress) {
                return (
                    <>
                        <Address 
                            address={address} 
                            updateAddress={updateSecundairyAddress}
                            country={company?.mainAddress?.country}
                            header={otherAddrHeader}
                            readOnly={!editMode}
                        />
                        <div className='row'>
                        <div className='col-md-3'>
                            { editMode && <FormButton
                                onClick={showOtherAddressHandler} 
                                level='primary' 
                                label='button.reset'
                            /> }
                        </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <div className='col-md-3'>
                        { editMode && <FormButton
                            onClick={showOtherAddressHandler} 
                            level='primary' 
                            label={otherAddress}
                        /> } 
                    </div>
                )
            }
        } else {
            return null;
        }
   }   

   const isEU = company?.mainAddress?.country ? isEUCountry(company.mainAddress.country) : true;

   const dontShowSearchButton = !(editVATNumber && editMode && isEU);

   const fields = [
        {fType: "select", label: "label.country", list: allowChangeNonEU ? resources.addressCountries : resources.countriesEU, order: true, 
            name: "country", value: company?.mainAddress?.country,updateObject: updateCompanyAddress, readOnly: !editMode},    
        {fType: "row", fields : [
            {fType: "input", col: dontShowSearchButton ? 12 : 10,label: "label.VATNumber", name: "VATNumber", value: company.VATNumber, onChange: changeHandler, 
                readOnly: !editMode || !editVATNumber || !company?.mainAddress?.country},    
            {fType: "button", col:2,label: "button.search", onClick: searchHandler, level: "primary", dontShow: dontShowSearchButton},
        ]},
        {fType: "button", label: "button.update", onClick: searchHandler, level: "secondary", dontShow: !(!editVATNumber && editMode && isEU)},
        {fType: "input", label: "label.companyName", name: "name", value: company.name, updateObject: updateCompany, readOnly: !editMode || isEU},
        {fType: "error", error: error},
        {fType: "title", label: addressHeader, dontShow: !addressHeader},
        {fType: "input", label: "label.street", name: "street", value: company?.mainAddress?.street || '', updateObject: updateCompanyAddress,
            readOnly: !editMode || isEU},
        {fType: "input", label: "label.postalCode", name: "postalCode", value: company?.mainAddress?.postalCode || '', 
            updateObject: updateCompanyAddress,readOnly: !editMode || isEU},
        {fType: "input", label: "label.city", name: "city", value: company?.mainAddress?.city || '', updateObject: updateCompanyAddress,
            readOnly: !editMode || isEU},
    ]

   return (
        <div className="CompanyData mb-5">
            <FormCard header={noHeader ? "" : header} centerHeader={true} fields={fields}/>
            {checkForSecundairyAddress()}
        </div>
    );
}