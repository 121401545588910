import React, { useCallback,useState } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormButton from '../layout/forms/FormButton';
import { log } from '../../Log';
import { CallGetCompany } from './CallVies';
import resources from '../../resources/lists/dropdownlist.json';

export default function SearchCompanyVies() {
    const [input,setInput] = useState({});
    const [output,setOutput] = useState({});
    const [error, setError] = useState({message: '',code: '' ,errorJSON: {}});

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        setInput((prevInput) => ({ ...prevInput, [name]: value })); 
    }, []);

    const searchHandler = useCallback(() => {
        log.debug("searchHandler");
        log.debug(input);
        CallGetCompany(input.country,input.vatNumber,response => {
            log.debug(response);
            if (response.err || response.errCode) {
                setError({message: response.err,code: response.errCode, errorJSON: {input}});
            } else {
                setError({message: '',code: '' ,errorJSON: {}});
                setOutput(response);
            }
        }); 
    }, [input]);

    return (
        <div className="SearchCompanyVies">
            <FormLineParagHeader label={"title.searchCompanyVies"}/>
            <div className='row'>
                <FormLineSelect 
                    col={3} 
                    list={resources.countriesEU} 
                    name="country" 
                    label="label.country" 
                    value={input.country} 
                    onChange={changeHandler} 
                    order={true}
                />
                <FormLineInput 
                    col={3} 
                    label="label.VATNumber" 
                    onChange={changeHandler} 
                    name="vatNumber" 
                    value={input.vatNumber}
                />
            </div>
            <FormLineErrorMessage error={error}/>
            <FormButton onClick={searchHandler} label="button.search"/>
            <div className='row'>
                <FormLineInput 
                    col={3} 
                    label="label.companyName" 
                    name="name" 
                    readOnly 
                    value={output.name}
                />
                <FormLineInput 
                    col={3} 
                    label="label.country" 
                    name="country" 
                    readOnly 
                    value={output.countryCode}
                />
                <FormLineInput 
                    col={3} 
                    label="label.VATNumber" 
                    name="vatNumber" 
                    readOnly 
                    value={output.vatNumber}
                />
                <FormLineInput 
                    col={3} 
                    label="label.valid" 
                    name="valid" 
                    readOnly 
                    value={output.valid}
                />
            </div>
            <div className='row'>
                <FormLineInput  
                    col={6}
                    label="label.street" 
                    name="street" 
                    readOnly 
                    value={output?.address?.street || ''}
                /> 
                <FormLineInput  
                    col={3}
                    label="label.postalCode" 
                    name="postalCode" 
                    readOnly 
                    value={output?.address?.postalCode || ''}
                /> 
                <FormLineInput  
                    col={3}
                    label="label.city" 
                    name="city" 
                    readOnly 
                    value={output?.address?.city || ''}
                /> 
            </div>
        </div>
    )
}