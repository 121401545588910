import React, { Suspense } from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './css/Drinkereez.min.css'
import 'bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import "bootstrap/dist/js/bootstrap.bundle.min.js";

import App from './App';

// index.js or App.js
const link1 = document.createElement("link");
link1.href = "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap";
link1.rel = "stylesheet";
document.head.appendChild(link1);

const link2 = document.createElement("link");
link2.href = "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
link2.rel = "stylesheet";
document.head.appendChild(link2);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en','nl','de','es','fr','it'],
    fallbackLng: "en",
    detection: {
      order: ['path','cookie','htmlTag','localStorage','subdomain'],
      caches: ['cookie'],
    },
    backend: {
      allowMultiLoading: true,
      loadPath: '/assets/locales/{{lng}}/resources.json',
    },
    react: {useSuspense: false}
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
)

/*
ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
*/

// Create a root.
const root = createRoot(document.getElementById('root'));

// Render the app using the root.
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
