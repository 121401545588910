import React, {useState, useContext} from 'react'
import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInputV2';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import { CallCheckUserNamePassword } from './CallUser';
import { CallGetClientFromUser } from '../Client/CallClient';
import {log} from '../../Log'; 
import FormCard from '../layout/forms/FormCard';

export default function LoginScreen() {
    const {setLogin, setUser, setClient} = useContext(AppContext);

    const [logon, setLogon] = useState({username: '',password: ''});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function changeHandler (e) {
        const changes = {[e.name]: e.value};
        setLogon({...logon,...changes});
    }

    function doReset () {
        setLogon({username: '',password: ''});
        setError({errorMessage: '',errorJSON: {}});
    }

    function submitHandler (e) {
        setError({errorMessage: '',errorJSON: {}});
        e.preventDefault();

        log.debug("execute await statements");
        CallCheckUserNamePassword(logon.username,logon.password,response => {
            if (response.err) {
                setError({errorMessage: response.err, errorJSON:{}});
            } else {
                const user = response;
                const login = {usr: user.id, pwd: logon.password};
                if (user.userType!=="USER_TYPE_ADMIN") CallGetClientFromUser(login,resp => {
                    if (resp.err) {
                        setError({errorMessage: resp.err, errorJSON:{}});
                    } else {
                        setLogin({...login});
                        setUser({...user});
                        setClient({...resp});
                        doReset();
                    }
                });
                else {
                    setLogin({...login});
                    setUser({...user});
                    doReset();                    
                }
            }            
        });
    }

    /*const rows = [
        [
            {fType: "input", col: 5, type: "input", label: "label.username", name: "username",value: logon.username,onChange: changeHandler}
        ],
        [
            {fType: "input", col: 5, type: "password", label: "label.password", name: "password",value: logon.password,onChange: changeHandler}
        ]
    ]*/

    const fields = [
        {fType: "input", type: "input", label: "label.username", name: "username",value: logon.username,onChange: changeHandler},
        {fType: "input", type: "password", label: "label.password", name: "password",value: logon.password,onChange: changeHandler},
        {fType: "error", message: error.errorMessage, errorJSON: error.errorJSON},
        {fType: "button", type: "submit", onClick: submitHandler,label: "button.submit"},
        {fType: "button", type: "reset", onClick: doReset,label: "button.reset", level: "secondary"}
    ]

    return (
        <div className="d-flex justify-content-center">
        <div className='mb-3' style={{ width: "500px" }}>
            <FormCard fields={fields}/>
        </div>
        </div>
    )

    /*
                <div className='row'>
                <FormLineInput col={5} type="input" label="label.username" name="username" value={logon.username} onChange={changeHandler} required={true}/>
            </div>
            <div className='row'>
                <FormLineInput col={5} type="password" label="label.password" name="password" value={logon.password} onChange={changeHandler} required={true}/>
            </div>
                        <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton type="submit" onClick={submitHandler} label='button.submit'/>
            <FormButton type="reset" onClick={doReset} label='button.reset' level='secondary'/>
    */
}
