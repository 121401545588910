import React, { useCallback } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';

ShowShipmentItem.propTypes = {
    header: PropTypes.string,
    //id: PropTypes.string,
    name: PropTypes.string,
    cnCode: PropTypes.string,
    alcoholPercentage: PropTypes.number,
    volumePerUnit: PropTypes.number,
    numberOfUnits: PropTypes.number,
    totalVolume: PropTypes.number,
    numberOfCollis: PropTypes.number,
    updateItem: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    item: PropTypes.object
};

ShowShipmentItem.defaultProps = {
    editMode: false
};

export default function ShowShipmentItem({
    header,
    updateItem,
    editMode = false,
    item = {},
    //id,
    name,
    cnCode,
    alcoholPercentage,
    volumePerUnit,
    numberOfUnits,
    totalVolume,
    numberOfCollis
}) {
    const itemName = item.name || name;
    const itemCnCode = item.cnCode || cnCode;
    const itemAlcoholPercentage = item.alcoholPercentage || alcoholPercentage;
    const itemVolumePerUnit = item.volumePerUnit || volumePerUnit;
    const itemNumberOfUnits = item.numberOfUnits || numberOfUnits;
    const itemTotalVolume = item.totalVolume || totalVolume;
    const itemNumberOfCollis = item.numberOfCollis || numberOfCollis;
    //const itemId = item.id || id;

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        updateItem({ [name]: value });
    }, [updateItem]);

    return (
        <div className="ItemData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput
                    col={6}
                    label="label.itemName"
                    name="name"
                    readOnly={!editMode}
                    value={itemName}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.cnCode"
                    name="cnCode"
                    readOnly={!editMode}
                    value={itemCnCode}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.alcoholPercentage"
                    name="alcoholPercentage"
                    readOnly={!editMode}
                    value={itemAlcoholPercentage}
                    onChange={changeHandler}
                    required
                />
            </div>
            <div className='row'>
                <FormLineInput
                    col={3}
                    label="label.volumePerUnit"
                    name="volumePerUnit"
                    readOnly={!editMode}
                    value={itemVolumePerUnit}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.numberOfUnits"
                    name="numberOfUnits"
                    readOnly={!editMode}
                    value={itemNumberOfUnits}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.totalVolume"
                    name="totalVolume"
                    readOnly={!editMode}
                    value={itemTotalVolume}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.numberOfCollis"
                    name="numberOfCollis"
                    readOnly={!editMode}
                    value={itemNumberOfCollis}
                    onChange={changeHandler}
                    required
                />
            </div>
        </div>
    );
}