import React, { useCallback } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';
import FormLineSelect from '../layout/forms/FormLineSelect';
import resources from '../../resources/lists/dropdownlist.json';
import { log } from '../../Log';
import FormCard from '../layout/forms/FormCard';

ShowTransport.propTypes = {
    type: PropTypes.string,
    waybill: PropTypes.string,
    trackAndTrace: PropTypes.string,
    header: PropTypes.string,
    updateTransport: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    transport: PropTypes.object
};

ShowTransport.defaultProps = {
    editMode: false
};

export default function ShowTransport({
    header,
    updateTransport,
    editMode,
    transport = {},
    type,
    waybill,
    trackAndTrace,
}) {
    const transportType = transport.type || type;
    const transportWaybill = transport.waybill || waybill;
    const transportTrackAndTrace = transport.trackAndTrace || trackAndTrace;

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        updateTransport({ [name]: value });
    }, [updateTransport]);

    const fields = [
        {fType: "select", label: "label.type", list: resources.transportTypes, order: true, name: "type", value: transportType, onChange: changeHandler, readOnly: !editMode},
        { fType: "input", type: "text", label: "label.waybill", name: "waybill", value: transportWaybill, onChange: changeHandler, readOnly: !editMode, required: true },    
        { fType: "input", type: "text", label: "label.trackAndTrace", name: "trackAndTrace", value: transportTrackAndTrace, onChange: changeHandler, readOnly: !editMode, required: true },    
    ];

    return (
        <div className="TransportData mb-5">
            <FormCard header={header} centerHeader={true} fields={fields} />
        </div>
    );


  /*  return (
        <div className="TransportData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineSelect
                    col={3}
                    list={resources.transportTypes}
                    label="label.type"
                    name="type"
                    readOnly={!editMode}
                    value={transportType}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.waybill"
                    name="waybill"
                    readOnly={!editMode}
                    value={transportWaybill}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.trackAndTrace"
                    name="trackAndTrace"
                    readOnly={!editMode}
                    value={transportTrackAndTrace}
                    onChange={changeHandler}
                    required
                />
            </div>
        </div>
    );*/
}
