import React from 'react';
import { useLayoutDecimal } from '../../GeneralFunc';
import { useTranslation } from "react-i18next";
import { validNumber,validDecNumber,validOnlyLetters } from '../../Regex';
//import { isValidPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import PropTypes from 'prop-types';
//import {log} from '../../../Log';

FormLineInputV2.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.oneOf(['input','text', 'number', 'email', 'password', 'decNumber', 'onlyLetters', 'phoneNumber']),
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    updateObject: PropTypes.func,
    col: PropTypes.number,
    handleClick: PropTypes.func,
    addPointer: PropTypes.bool
};

FormLineInputV2.defaultProps = {
    type: 'text',
    required: false,
    readOnly: false,
    maxLength: 50,
    placeholder: 'value',
    className: 'my-2',
    col: 12,
    addPointer: false
};

export default function FormLineInputV2(props) {
    const { t } = useTranslation();
    const { name, value, type, label, required, readOnly, maxLength, placeholder, 
        className, onChange, updateObject, ref, col, donotShowEmpty,handleClick, addPointer } = props;
    const formattedValue = useLayoutDecimal(value);
    const inputClassName = (addPointer ? "cursor-pointer " : "") + "form-control border-0 bottom-border-only";  

    const changeHandler = (e) => {
        const inputValue = e.target.value;
    
        if (
            (type === 'number' && !validNumber.test(inputValue)) ||
            (type === 'decNumber' && !validDecNumber.test(inputValue)) ||
            (type === 'onlyLetters' && !validOnlyLetters.test(inputValue))
        ) {
            return;
        }
    
        const formValue = type === 'decNumber' ? inputValue.replace(',', '.') : inputValue;
    
        if (onChange) {
            onChange({ name, value: formValue });
        } else if (updateObject) {
            updateObject({ [name]: formValue });
        }
    };

    const layoutValue = () => (
        type === 'decNumber' ? formattedValue : value
    );

    const getEffectiveType = () => (['number', 'decNumber', 'onlyLetters', 'phoneNumber'].includes(type) ? 'text' : type);

    if (donotShowEmpty && !value && readOnly) {
        return null;
    }

    return (
        <div className={`form-floating custom-form-group ${className} col-md-${col}`}>
            <input
                ref={ref}
                className={`form-control ${inputClassName}`}
                id={name}
                type={getEffectiveType()}
                readOnly={readOnly}
                name={name}
                value={layoutValue()}
                onChange={changeHandler}
                required={required}
                placeholder={placeholder}
                maxLength={maxLength}
                onClick={handleClick}
            />
            <label htmlFor={name}>
                {t(label)}
            </label>
        </div>
    );
}