import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import shipmentTest from '../../resources/test/shipmentTest.json';

export function CallgetAllShipments (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllShipments");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (shipmentTest.shipments);
    }
    else {
        const body = { 
            req : {...login,action: 13}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveShipment (login,shipment,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';    
    log.debug("send JSON CallSaveShipment");

    if (testMode) {
        if (!shipment.id) {
            // Assign a random number as ID (example: a random number between 100000 and 999999)
            shipment.id = Math.floor(Math.random() * 900000) + 100000;
        }
        responseFunc (shipment);
        //responseFunc ({err : "error.server_return_error", errCode : "213548"});
    }
    else {
        const body = { 
            req : {...login,action: 14,shipment}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }    
}