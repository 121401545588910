import React from 'react'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';
import FormCard from '../layout/forms/FormCard'

NewPassword.propTypes = {
    oldPassword: PropTypes.any, // Replace 'any' with the specific type you expect
    newPassword: PropTypes.any, // Replace 'any' with the specific type you expect
    confirmedPassword: PropTypes.any, // Replace 'any' with the specific type you expect
    header: PropTypes.any, // Replace 'any' with the specific type you expect
    askOldPassword: PropTypes.any, // Replace 'any' with the specific type you expect
    updatePassword: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function NewPassword(props) {
    const {oldPassword,newPassword,confirmedPassword,header,askOldPassword,updatePassword} = props;
    const noHeader = !header || header===""; 

    function changeHandler (e) {
        updatePassword({[e.name]: e.value});
    }

    const fields = [
        {fType: "input", type: "password", label: "label.oldPassword", name: "oldPassword", value: oldPassword, onChange: changeHandler, dontShow: !askOldPassword},
        {fType: "input", type: "password", label: askOldPassword ? "label.newPassword" : "label.password", name: "newPassword", value: newPassword, onChange: changeHandler},
        {fType: "input", type: "password", label: "label.confirmedPassword", name: "confirmedPassword", value: confirmedPassword, onChange: changeHandler},
    ]

    return (
        <div className="NewPassword mb-5">
            <FormCard header={noHeader ? "" : header} fields={fields}/>
        </div>
    )

    /*
    return (
        <div className="NewPassword mb-5">
            { 
                !noHeader && <FormLineParagHeader label={header}/>
            } 
            <div className='row'>           
                {
                    askOldPassword && <FormLineInput col={4} type="password" label="label.oldPassword" name="oldPassword" value={oldPassword} onChange={changeHandler} required={true}/>
                }
            </div>
            <div className='row'>
                {
                    askOldPassword ? <FormLineInput col={4} type="password" label="label.newPassword" name="newPassword" value={newPassword} onChange={changeHandler} required={true}/> : 
                                <FormLineInput col={4} type="password" label="label.password" name="newPassword" value={newPassword} onChange={changeHandler} required={true}/>
                }
            </div>
            <div className='row'>
                <FormLineInput col={4} type="password" label="label.confirmedPassword" name="confirmedPassword" value={confirmedPassword} onChange={changeHandler} required={true}/>
            </div>
        </div>
    )*/
}
