import React,{useCallback} from 'react'
import FormLineInput from './FormLineInputV2';
import FormLineSelect from './FormLineSelectV2';
import FormLineParagHeader from './FormLineParagHeader';
import PropTypes from 'prop-types';
import { log } from '../../../Log';
import FormButton from './FormButton';
import FormLineErrorMessage from './FormLineErrorMessage';

FormCard.propTypes = {
    header: PropTypes.string,
    centerHeader: PropTypes.bool,
    rows: PropTypes.array,
    fields: PropTypes.array
}
export default function FormCard ({header,centerHeader,rows,fields}) {
    const className = centerHeader ? "text-center" : ""
    const addHeader = header && header!=="" ? <FormLineParagHeader label={header} className={className}/> : null;

    const addField = useCallback((field) => {
        if (field.dontShow) 
            return

        return field.fType === "input" ? <FormLineInput {...field} /> :
               field.fType === "select" ? <FormLineSelect {...field} />: 
               field.fType === "button" ? <FormButton {...field} /> :
               field.fType === "title" ? <FormLineParagHeader {...field} /> :
               field.fType === "error" ? <FormLineErrorMessage {...field} /> : 
               field.fType === "row" ? addRow(field) :
               null;
    }, []);

    const addRow = useCallback((row) => {
        if (!row.fields)
            return null;
        return (
            <div className="row">
                {row.fields.map(field => addField(field))}
            </div>
        );
    }, [addField]);

    const addContent =  useCallback(() => {
        if (rows) {
            return rows.map(row => addRow(row));
        } if (fields)
            return fields.map(field => addField(field));
        return;
    }, [rows,fields,addRow,addField]);

    return (
        <div className="FormCard"> 
            {addHeader}
            {addContent()}
        </div>
    ); 
}