import React, { useContext,useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import Calculator from '../Calculator/Calculator';
import UserData from './UserData';
import ShowProducts from '../Product/ShowProducts';
import ClientServicesPage from '../Client/ClientServicesPage';
import UpdateClient from '../Client/UpdateClient';
import { AppContext } from '../Drinkereez';
import ShipmentPage from '../Shipment/ShipmentPage';
//import {log} from '../../Log';

export default function UserPortal(props) {
    const { client } = useContext(AppContext);
    const { screenToShow } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if ((screenToShow==="home") && ((client && (!client.services || client.services.length === 0)))) {
            navigate("/portal/servicesList");
        }
    }, [client,navigate, screenToShow]);

    function goToHome() {
        navigate("/portal/home");
    }

    // Determine the main content based on user state and screenToShow
    let mainContent;
    switch (screenToShow) {
        case "calculator": mainContent = <Calculator />; break;
        case "userData": mainContent = <UserData />; break;
        case "clientData": mainContent = <UpdateClient stopShowClient={goToHome} clientSaved={goToHome} showContactperson={false}/>; break;
        case "servicesList": mainContent = <ClientServicesPage />; break;
        case "products": mainContent = <ShowProducts />; break;
        case "shipments": mainContent = <ShipmentPage />; break;
        default: mainContent = null;
    }

    return (
        <div className="userPortal container">
            {mainContent}           
        </div>
    )
}
