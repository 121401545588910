import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './Drinkereez';

export default function Logout() {
    const navigate = useNavigate();
    const { setUser, setLogin, setProducer, setClient } = useContext(AppContext);

    useEffect(() => {
        setUser(null);
        setLogin(false);
        setProducer(null);
        setClient(null);

        sessionStorage.clear();
        localStorage.removeItem("authToken");

        navigate("/login");
    }, [navigate, setUser, setLogin, setProducer, setClient]);

    return null; 
}