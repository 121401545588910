import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { log } from '../../../Log'

FormPageTitle.propTypes = {
    col: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    className: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function FormPageTitle (props) {
    const {t} = useTranslation();
    const {col,label,className} = props;
    const extraClassName = className ? className : 'mt-2';
    const c = col ? col : 12;
    const classN = "form-group " + extraClassName + " col-md-" + c;

    return (
        <div className={classN}>
            <h2 className="text-md-left text-dark">{t(label)}</h2>
        </div>
    );  
}