import React from 'react';
import resources from '../../resources/lists/dropdownlist.json';
import PropTypes from 'prop-types';
import FormCard from '../layout/forms/FormCard';

ContactPerson.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    eMail: PropTypes.string,
    phoneNumber: PropTypes.string,
    languageCode: PropTypes.string,
    header: PropTypes.string,
    readOnly: PropTypes.bool,
    updateContactPerson: PropTypes.func.isRequired
};

export default function ContactPerson(props) {
    const { firstName, lastName, eMail, phoneNumber, languageCode, header, readOnly, updateContactPerson, decimalSeparator, person } = props;
    const noHeader = !header || header===""; 

    const fields = [
        {fType: "input", label: "label.firstName",name : "firstName", value: person.firstName, updateObject: updateContactPerson,readOnly},
        {fType: "input", label: "label.lastName",name : "lastName", value: person.lastName, updateObject: updateContactPerson,readOnly},
        {fType: "select", list: resources.languageCodes,label: "label.language",name : "languageCode", value: person.languageCode, updateObject: updateContactPerson,readOnly},
        {fType: "input", label: "label.eMail",name : "eMail", value: person.eMail, updateObject: updateContactPerson,readOnly},
        {fType: "input", type: "phoneNumber", label: "label.phoneNumber",name : "phoneNumber", value: person.phoneNumber, updateObject: updateContactPerson,readOnly},
        //{fType: "select", list: resources.decimalSeparators,label: "label.decimalSeparator",name : "decimalSeparator", value: person.decimalSeparator, updateObject: updateContactPerson,readOnly}
    ]

    return (
        <div className="ContactPerson mb-5">
            <FormCard header={noHeader ? "" : header} fields={fields}/>
        </div>
    );
}
