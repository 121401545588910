import React, {useContext} from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Drinkereez';
import PropTypes from 'prop-types';

AdminPortalNavBar.propTypes = {
    navOptionClicked: PropTypes.func.isRequired // Expecting a function type
};

export default function AdminPortalNavBar(props) {
    const {navOptionClicked} = props;
    const {t} = useTranslation();
    const {user} = useContext(AppContext);
    const navigate = useNavigate();

    const navItems = [
        { id: 'orders', label: t('tab.navbar_orders')},
        { id: 'producers', label: t('tab.navbar_producers')},
        { id: 'product', label: t('tab.navbar_products')},
        { id: 'dropdown_excises', label: t('tab.navbar_excises'), isDropdown: true,
                    dropdownItems: [
                        { id: 'excises', label: t('tab.navbar_excises') },
                        { id: 'xml', label: t('tab.navbar_xmls')}
                    ]
        },
        { id: 'dropdown_clients', label: t('tab.navbar_clients'), isDropdown: true,
                    dropdownItems: [
                        { id: 'users', label: t('tab.navbar_users') },
                        { id: 'clients', label: t('tab.navbar_clients') },
                        { id: 'clientServices', label: t('tab.navbar_clientServices')}
                    ]
        },
        { id: 'tools', label: t('tab.navbar_tools'), isDropdown: true,
                    dropdownItems: [
                        { id: 'calculator', label: t('tab.navbar_calculator') },
                        { id: 'converter', label: t('tab.navbar_converter') },
                        { id: 'userData', label: `${user.person.firstName} ${user.person.lastName}` }, 
                        { id: 'natCode', label: t('tab.navbar_natCode') }, 
                        { id: 'tarNatCode', label: t('tab.navbar_tarNatCode') }, 
                        { id: 'natEUCode', label: t('tab.navbar_natEUCode') }, 
                        { id: 'uploadFile', label: t('tab.navbar_uploadFile') }, 
                        { id: 'vies', label: t('tab.navbar_vies') },
                        { id: 'seed', label: t('tab.navbar_seed') },
                        { id: 'logout', label: t('tab.navbar_logout')}
                    ]}
    ];

    function optionClicked (e) {
        const option = e.target.id;
        if (option === 'logout') {
            navOptionClicked(option)
        } else {
            navigate(`/admin-portal/${option}`);
        }
    }

    function renderNavItem(item) {
        if (item.dropdownItems) {
            return (
                <li className="nav-item">
                    <a id={item.id} className="nav-link link-dark dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" href="#nogo">{item.label}</a>
                    <ul className="dropdown-menu">
                        {item.dropdownItems.map(subItem => {
                            return <li><a className="dropdown-item" href="#nogo" id={subItem.id} onClick={optionClicked}>{subItem.label}</a></li>;
                        })}
                    </ul>
                </li>
            );
        }

        return (
            <li className="nav-item">
                <a id={item.id} className="nav-link link-dark" href="#nogo" onClick={optionClicked}>{item.label}</a>
            </li>
        );
    }

    return (
        <ul className="nav nav-tabs nav-justified">
            {navItems.map(renderNavItem).filter(item => item !== null)}
        </ul>
    )
}