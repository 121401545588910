import React, {useContext,useEffect,useState,useMemo,useCallback} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import { CallgetAllConsigneeShipments,CallgetAllConsignorShipments } from './CallShipment';
import TableLayout from '../layout/tables/TableLayout';
import { getLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';

ShowShipments.propTypes = {
    shipmentSelected: PropTypes.func.isRequired,  // Ensure this is a function prop
    asConsignee: PropTypes.bool
};

ShowShipments.defaultProps = {
    asConsignee: true
};

export default function ShowShipments({ shipmentSelected, asConsignee }) {
    const {login,client} = useContext(AppContext);
    const consign = asConsignee ? "label.consignor" : "label.consignee";
    const columns = ["label.registrationDate","label.totalAmount",consign,"label.state"];
    const [shipments,setShipments] = useState ([]);

    const getAllShipments = useCallback(() => {
        log.debug("ShowShipments - getAllShipments");

        const fetchShipments = asConsignee ? CallgetAllConsigneeShipments : CallgetAllConsignorShipments;

        fetchShipments (login,client.company.id,response => {
            if (response.err) {
                log.error("API call error: ", response.err);
                return;
            }
            if (response.ret?.err) {
                log.error("Response error: ", response.ret.err);
                return;
            }
            log.debug(response);
            setShipments(response.shipments);
        });
    }, [login,client,asConsignee]); 


    useEffect(() => {
        getAllShipments();
    },[getAllShipments]);

    const onClickTable = useCallback(
        (e) => {
            shipmentSelected(shipments.find(({ id }) => id === e.currentTarget.id));
        },
        [shipments, shipmentSelected]
    );

    function addShipment () {
        log.debug("addShipment");

        // Format current date and time as "YYYY-MM-DD HH:mm:ss"
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
    
        // Define common properties for the shipment
        const baseShipment = {
            state: "SHIPMENT_STATE_DRAFT",
            registrationDate: formattedDate,
        };
    
        // Add specific properties based on consignee or consignor
        const shipment = asConsignee
            ? { ...baseShipment, consignee: client.company }
            : { ...baseShipment, consignor: client.company };
    
        shipmentSelected(shipment);
    }

    // UseMemo to optimize the rendering of table rows
    const renderedRows = useMemo(() => {
        if (!shipments || shipments.length === 0) {
            return <tr><td colSpan={columns.length}>No shipments available</td></tr>;
        }
    
        return shipments.map((shipment) => {
            //log.debug("ShowShipments - renderedRows - shipment: " + shipment.id);
            const name = asConsignee ? shipment.consignor?.name : shipment.consignee?.name;
            const type = "amount_" + shipment.invoice?.currency;
            //log.debug("type : " + type);
            return (
                <tr key={shipment.id}>
                    <TableRowCell first="true" value={shipment.registrationDate} onClick={onClickTable} id={shipment.id} />
                    <TableRowCell value={shipment.invoice?.totalAmount} type={type}/>
                    <TableRowCell value={name} />
                    <TableRowCell label={getLabelFromList(resources.shipmentStates, shipment.state)} />
                </tr>
            );
        });
    }, [shipments, onClickTable, asConsignee, columns.length]);

    const displayData = () => renderedRows;

    const title = asConsignee ? "title.consigneeShipments" : "title.consignorShipments";

    return (
        <div className="ShowShipments">
            <div className='row'>
                <FormLineParagHeader col={10} label={title}/>
                <FormButton col={2} onClick={addShipment} label={"button.add"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}