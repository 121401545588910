import React, { useState, useCallback } from 'react';
import ContactPerson from '../General/ContactPersonV2';
import NewPassword from '../General/NewPassword';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import { validEmail, validPassword } from '../Regex';
import { CallRegistrateClient } from './CallClient';
import FormButton from '../layout/forms/FormButton';
import GetCompany from '../General/GetCompanyV2';
import { useNavigate } from 'react-router-dom';

export default function RegisterClient() {
    const [contactPerson, setContactPerson] = useState({});
    const [contactAddress, setContactAddress] = useState({});
    const [company, setCompany] = useState({});
    const [newPasswords, setNewPasswords] = useState({});
    const [error, setError] = useState({ message: '', code: '', errorJSON: {} });
    const navigate = useNavigate();

    /** Resets all form fields */
    const resetForm = () => {
        setContactPerson({});
        setContactAddress({});
        setCompany({});
        setNewPasswords({});
        setError({ message: '', code: '', errorJSON: {} });
    };

    /** Validates form input before submission */
    const validateForm = () => {
        if (!company.VATNumber) return { code: 'VATNumber_missing' };
        if (!company.mainAddress?.street) return { code: 'Address_missing' };
        if (!contactPerson.firstName || !contactPerson.lastName) return { code: 'Name_missing' };
        if (!validEmail.test(contactPerson.eMail)) return { code: 'incorrect_regex_email' };
        //if (!validPassword.test(newPasswords.newPassword)) return { code: 'incorrect_regex_password' };
        if (newPasswords.newPassword !== newPasswords.confirmedPassword) return { code: 'password_not_equal' };
        return null;
    };

    /** Handles form submission */
    const handleSubmit = (e) => {
        e.preventDefault();
        setError({ message: '', code: '', errorJSON: {} });

        const validationError = validateForm();
        if (validationError) {
            setError({ ...validationError, errorJSON: {} });
            return;
        }

        CallRegistrateClient(contactPerson, company, contactAddress, newPasswords, (response) => {
            if (response.err || response.errCode) {
                setError({ message: response.err, code: response.errCode, errorJSON: { contactPerson, company, contactAddress, newPasswords } });
            } else {
                navigate('/login');
            }
        });
    };

    const updateContactPerson = useCallback((change) => {
        setContactPerson((prev) => ({ ...prev, ...change }));
    }, []);

    const updateContactAddress = useCallback((change) => {
        setContactAddress((prev) => ({ ...prev, ...change }));
    }, []);

    const updateCompany = useCallback((change) => {
        setCompany((prev) => ({ ...prev, ...change }));
    }, []);

    const updateCompanyAddress = useCallback((change) => {
        setCompany((prev) => ({
            ...prev,
            mainAddress: { ...prev.mainAddress, ...change }
        }));
    }, []);

    const updateNewPassword = useCallback((change) => {
        setNewPasswords((prev) => ({ ...prev, ...change }));
    }, []);

    return (
        <div className="d-flex justify-content-center">
            <div className="mb-3" style={{ width: "500px" }}>
                <GetCompany
                    company={company}
                    address={contactAddress}
                    updateCompany={updateCompany}
                    updateCompanyAddress={updateCompanyAddress}
                    editMode
                    editVATNumber
                    allowChangeNonEU
                />
                <ContactPerson person={contactPerson} updateContactPerson={updateContactPerson} />
                <NewPassword {...newPasswords} updatePassword={updateNewPassword} askOldPassword={false} />
                <FormLineErrorMessage error={error} />
                <FormButton type="submit" onClick={handleSubmit} label="button.submit" />
                <FormButton type="reset" onClick={resetForm} label="button.reset" level="secondary" />
            </div>
        </div>
    );
}