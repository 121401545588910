import React, { useContext,useState, useCallback} from 'react';
import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import resources from '../../resources/lists/dropdownlist.json';
import ShowTransport from '../General/ShowTransport';
import { log } from '../../Log';
import GetCompany from '../General/GetCompanyV2';
import ShowShipmentItems from './ShowShipmentItems';
import ShowShipmentItem from './ShowShipmentItem';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import { CallSaveShipment } from './CallShipment';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';
import ShowInvoice from '../General/ShowInvoice';
import FormCard from '../layout/forms/FormCard';
import FormPageTitle from '../layout/forms/FormPageTitle';
//import { CallUploadFile } from '../UploadFile/CallUploadFile'

ShowShipment.propTypes = {
    shipment: PropTypes.object,
    stopShowShipment: PropTypes.func
};

export default function ShowShipment({ shipment: initialShipment, stopShowShipment }) {
    const { login,client } = useContext(AppContext);
    const [shipment, setShipment] = useState(initialShipment || {});
    const [itemSelected, setItemSelected] = useState();
    const [editMode, setEditMode] = useState(!shipment.id);
    const [error, setError] = useState({message: '',code: '' ,errorJSON: {}});
    const [successMessage, setSuccessMessage] = useState('');
    const [tempId, setTempId] = useState(0);
    const asConsignee = shipment.consignee && shipment.consignee.id === client.company.id;

    const clearMessages = useCallback(() => {
        setError({ message: '', code: '', errorJSON: {} });
        setSuccessMessage('');
      }, []);

    const changeHandler = useCallback((e) => {
        const { name, value } = e.target;
        setShipment((prevShipment) => ({ ...prevShipment, [name]: value }));
    }, []);

    const updateConsignee = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,consignee: { ...prevShipment.consignee, ...change },}));
    }, []);

/*    const updateConsigneeAddress = useCallback((change) => {
        const address = {...shipment.consignee.address, ...change};
        setShipment((prevShipment) => ({...prevShipment,consignee: { ...prevShipment.consignee, useOfficialAddress: !(Object.keys(address).length > 0), address: address },}));
    }, [shipment.consignee?.address]);*/

    const updateConsigneeAddress = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,consigneeAddress : { ...prevShipment.consigneeAddress, ...change }}));
    }, [shipment.consigneeAddress]);

    const updateConsigneeMainAddress = useCallback((change) => {
        log.debug("update updateConsigneeMainAddress");
        log.debug(change);
        setShipment((prevShipment) => ({...prevShipment,consignee : { ...prevShipment.consignee, mainAddress: { ...prevShipment.consignee?.mainAddress, ...change } }}));
    }, [shipment.consignee]);

    const updateConsignorMainAddress = useCallback((change) => {
        log.debug("update updateConsignorMainAddress");
        log.debug(change);
        setShipment((prevShipment) => ({...prevShipment,consignor : { ...prevShipment.consignor, mainAddress: { ...prevShipment.consignor?.mainAddress, ...change } }}));
    }, [shipment.consignor]);

    const updateConsignor = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,consignor: { ...prevShipment.consignor, ...change },}));
    }, []);

/*    const updateConsignorAddress = useCallback((change) => {
        const address = {...shipment.consignor.address, ...change};
        setShipment((prevShipment) => ({...prevShipment,consignor: { ...prevShipment.consignor, useOfficialAddress: !(Object.keys(address).length > 0), address: address },}));
    }, [shipment.consignor?.address]);*/

    const updateConsignorAddress = useCallback((change) => {
        log.debug("update ConsignorAddress");
        log.debug(change);
        setShipment((prevShipment) => ({...prevShipment,consignorAddress : { ...prevShipment.consignorAddress, ...change }}));
    }, [shipment.consignorAddress]);

    const updateTransport = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,transport: { ...prevShipment.transport, ...change },}));
    }, []);

    const updateInvoice = useCallback((change) => {
        setShipment((prevShipment) => ({...prevShipment,invoice: { ...prevShipment.invoice, ...change },}));
    }, []);

    const editHandler = useCallback(() => {
        clearMessages();
        setEditMode(true);
    }, [clearMessages, setEditMode]);

    const saveShipment = useCallback(() => {
        CallSaveShipment(login, shipment, (response) => {
          log.debug(response);
          if (response.err || response.errCode) {
            setError({ message: response.err, code: response.errCode, errorJSON: { shipment } });
          } else {
            setSuccessMessage("Saved shipment successfully");
            setEditMode(false);
            stopShowShipment();
          }
        });
      }, [login, shipment, stopShowShipment]);

    const saveHandler = useCallback(async () => {
        clearMessages();
        saveShipment();
    }, [saveShipment, clearMessages]);

    const cancelHandler = useCallback(() => {
        stopShowShipment();
    }, [stopShowShipment]);

    function editOrSaveButton() {
        if (shipment.state !== "SHIPMENT_STATE_DRAFT") return;
        if (editMode) {
            return (
                <FormButton
                    onClick={saveHandler}
                    level='primary'
                    label='button.save'
                />
            )
        } else {
            return (
                <FormButton
                    onClick={editHandler}
                    level='primary'
                    label='button.edit'
                />
            )
        }
    }

    const sendInShipment = useCallback(() => {
        clearMessages();
        let errMessage = "";

        if (!shipment.id) errMessage = errMessage + "You need to save the shipment first\n";
        if (!shipment.items || shipment.items.length === 0) errMessage = errMessage + "You need to add at least one item to the shipment\n";
        if (!shipment.consignee || !shipment.consignee.id) errMessage = errMessage + "You need to add a consignee to the shipment\n";
        if (!shipment.consignor || !shipment.consignor.id) errMessage = errMessage + "You need to add a consignor to the shipment\n";
        if (!shipment.transport || !shipment.transport.id) errMessage = errMessage + "You need to add a transport to the shipment\n";
        else if (!shipment.transport.type) errMessage = errMessage + "You need to add a transport type to the shipment\n";
        if (!shipment.invoice || !shipment.invoice.id) errMessage = errMessage + "You need to add an invoice to the shipment\n";
        else if (!shipment.invoice.totalAmount) errMessage = errMessage + "You need to add an invoice amount to the shipment\n";
        else if (!shipment.invoice.document || !shipment.invoice.document.id) errMessage = errMessage + "You need to add an invoice document to the shipment\n";

        if (errMessage && errMessage.length > 0) {
            setError({ message: errMessage, code: '', errorJSON: { shipment } });
            return;
        }

        setShipment((prevShipment) => ({ ...prevShipment, state: "SHIPMENT_STATE_PROCESSING" }));
        saveShipment();
    })

    function showActionButton() {
        if (editMode) return;
        if (shipment.state === "SHIPMENT_STATE_DRAFT") return (
            <FormButton
                onClick={sendInShipment}
                level='primary'
                label='button.sendIn'
                col={2}
            />
        );
    }

    const updateItem = useCallback((e) => {
        setItemSelected((prevItem) => ({ ...prevItem, ...e }));
    }, []);

    const cancelItemHandler = useCallback(() => {
        setItemSelected(null);
    }, []);

    const newItemHandler = useCallback(() => {
        setItemSelected({ id: "temp" + tempId, name: '', cnCode: '', totalVolume: 0, 
            alcoholPercentage: 0,volumePerUnit: 0,numberOfUnits: 0,numberOfCollis: 0 });
        setTempId(tempId + 1);
    }, [tempId]);

    const saveItem = useCallback(() => {
        log.debug("save : itemSelected");
        log.debug(itemSelected);
        setShipment((prevShipment) => {
            const existingItems = prevShipment.items || [];
            const updatedItems = existingItems.some(item => item.id === itemSelected.id)
                ? existingItems.map(item => 
                    item.id === itemSelected.id ? itemSelected : item
                  ) // Replace the matching item
                : [...existingItems, itemSelected]; // Add the new item
    
            return {
                ...prevShipment,
                items: updatedItems,
            };
        });
    }, [itemSelected]);

    const saveItemHandler = useCallback(() => {
        saveItem();
        setItemSelected(null);
    }, [saveItem,setItemSelected]);

    const saveItemAndNextHandler = useCallback(() => {
        saveItem();
        setItemSelected(null);
        newItemHandler();
    }, [saveItem,newItemHandler]);

    function showItem() {
        if (itemSelected) {
            return (
                <>
                    <ShowShipmentItem
                        header="title.item"
                        item={itemSelected}
                        updateItem={updateItem}
                        editMode={editMode}
                    />
                    {editMode && <FormButton
                        onClick={saveItemHandler}
                        level='primary'
                        label='button.saveItem'
                    />}
                    {editMode && <FormButton
                        onClick={saveItemAndNextHandler}
                        level='primary'
                        label='button.saveAndNext'
                    />}
                    <FormButton 
                        onClick={cancelItemHandler} 
                        level='secondary' 
                        label='button.cancel'
                    />
                </>
            )
        }
    }

    const fields = [
        {fType: "input", label: "label.registrationDate", name: "registrationDate", value: shipment.registrationDate, readOnly: true},
        {fType: "select", label: "label.state", list: resources.shipmentStates, name: "state", value: shipment.state, readOnly: true}
    ];

    return (
        <div className="ShowShipment">
            <FormPageTitle label="title.shipment" className="text-center p-top-2em"/>
            <div className="ShipmentData mb-5">
                <FormCard fields={fields}/>
            </div>
            <div className="p-top-1em"></div>
            <GetCompany
                header="title.consignor"
                showAddressHeader={false}
                company={shipment.consignor}
                address={shipment.consignorAddress}
                updateCompany={updateConsignor}
                updateCompanyAddress={updateConsignorMainAddress}
                addressButton={"button.addConsignorAddress"}
                otherAddressHeader={"title.consignorAddress"}
                editMode={asConsignee && editMode}
                editVATNumber={!(shipment.id)}
            />
            <div className="p-top-1em"></div>
            <GetCompany
                header="title.consignee"
                showAddressHeader={false}
                company={shipment.consignee}
                address={shipment.consigneeAddress}
                updateCompany={updateConsignee}
                updateCompanyAddress={updateConsigneeMainAddress}
                addressButton={"button.addConsigneeAddress"}
                otherAddressHeader={"title.consigneeAddress"}
                editMode={!asConsignee && editMode}
                editVATNumber={!(shipment.id)}
            />
            <div className="p-top-1em"></div>
            <ShowInvoice
                header="title.invoice"
                invoice={shipment.invoice}
                updateInvoice={updateInvoice}
                editMode={editMode}
                setError={setError}
            />
            <div className="p-top-1em"></div>
            <ShowTransport
                header="title.transport"
                transport={shipment.transport}
                updateTransport={updateTransport}
                editMode={editMode}
            />
            <div className="p-top-1em"></div>
            <ShowShipmentItems
                items={shipment.items}
                setItemSelected={setItemSelected}
                newItem={newItemHandler}
                hideAddButton={!editMode}
            />
            {showItem()}
            <FormLineErrorMessage 
                error={error}
            />
            <FormLineSuccessMessage
                message={successMessage}
            />
            <div className="p-top-2em"></div>
            {!itemSelected && editOrSaveButton()}
            {!itemSelected && <FormButton 
                onClick={cancelHandler} 
                level='secondary' 
                label='button.cancel'
            />}
        </div>
    );
}
