import React,{useMemo} from 'react'
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableRowCell from '../layout/tables/TableRowCell';
import TableLayout from '../layout/tables/TableLayout';
import PropTypes from 'prop-types';

ShowShipmentItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        cnCode: PropTypes.string,
        totalVolume: PropTypes.number
    })).isRequired,
    itemSelected: PropTypes.func.isRequired
};

export default function ShowShipmentItems({items,itemSelected}) {
    const columns = ["label.name","label.cnCode","label.volume"];

    const handleClick = (e) => {
        const {id} = e.currentTarget;
        log.debug("clicked : " + id);
        itemSelected(items.find(item => item.id === id));
    };

    // Memoize the map operation but keep displayData as a function
    const renderRows = useMemo(() => {
        log.debug("display data");
        if (!items || items.length === 0) return null;

        return items.map(item => (
            <tr key={item.id}>
                <TableRowCell first="true" value={item.name} onClick={handleClick} id={item.id} />
                <TableRowCell value={item.cnCode} />
                <TableRowCell value={item.totalVolume} />
            </tr>
        ));
    }, [items]);

    const displayData = () => renderRows;
    
    return (
        <div className="ShowItems">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.items"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}