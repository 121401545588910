import React, { useCallback,useState } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormButton from '../layout/forms/FormButton';
import { log } from '../../Log';
import { CallVerifyExcise } from './CallSeed';
import resources from '../../resources/lists/dropdownlist.json';

export default function VerifyExciseSeed () {
    const [input,setInput] = useState({});
    const [output,setOutput] = useState({});
    const [error, setError] = useState({message: '',code: '' ,errorJSON: {}});

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        setInput((prevInput) => ({ ...prevInput, [name]: value })); 
    }, []);

    const verifyHandler = useCallback(() => {
        log.debug("verifyHandler");
        log.debug(input);
        CallVerifyExcise(input.exciseNumber,response => {
            log.debug(response);
            if (response.err || response.errCode) {
                setError({message: response.err,code: response.errCode, errorJSON: {input}});
            } else {
                setError({message: '',code: '' ,errorJSON: {}});
                log.debug(response);
                //setOutput(response);
            }
        }); 
    }, [input]);

    return (
        <div className="verifyExciseSeed">
            <FormLineParagHeader label={"title.verifyExciseSeed"}/>
            <div className='row'>
                <FormLineInput 
                    col={3} 
                    label="label.exciseNumber" 
                    onChange={changeHandler} 
                    name="exciseNumber" 
                    value={input.exciseNumber}
                />
            </div>
            <FormLineErrorMessage error={error}/>
            <FormButton onClick={verifyHandler} label="button.verify"/>
            <div className='row'>
                <FormLineInput 
                    col={3} 
                    label="label.companyName" 
                    name="name" 
                    readOnly 
                    value={output.name}
                />
                <FormLineInput 
                    col={3} 
                    label="label.country" 
                    name="country" 
                    readOnly 
                    value={output.countryCode}
                />
                <FormLineInput 
                    col={3} 
                    label="label.VATNumber" 
                    name="vatNumber" 
                    readOnly 
                    value={output.vatNumber}
                />
                <FormLineInput 
                    col={3} 
                    label="label.valid" 
                    name="valid" 
                    readOnly 
                    value={output.valid}
                />
            </div>
            <div className='row'>
                <FormLineInput  
                    col={6}
                    label="label.street" 
                    name="street" 
                    readOnly 
                    value={output?.address?.street || ''}
                /> 
                <FormLineInput  
                    col={3}
                    label="label.postalCode" 
                    name="postalCode" 
                    readOnly 
                    value={output?.address?.postalCode || ''}
                /> 
                <FormLineInput  
                    col={3}
                    label="label.city" 
                    name="city" 
                    readOnly 
                    value={output?.address?.city || ''}
                /> 
            </div>
        </div>
    )
}