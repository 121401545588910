import React, {useContext,useEffect,useState,useMemo} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import { CallgetAllShipments } from './CallShipment';
import TableLayout from '../layout/tables/TableLayout';
import { GetLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

ShowShipments.propTypes = {
    shipmentSelected: PropTypes.func.isRequired  // Ensure this is a function prop
};

export default function ShowShipments({ shipmentSelected }) {
    const {login} = useContext(AppContext);
    const columns = ["label.registrationDate","label.totalAmount","label.consignor","label.state"];
    const [shipments,setShipments] = useState ();

    useEffect(() => {
        getAllShipments();
      });

    function getAllShipments() {
        log.debug("ShowShipments - getAllShipments");
        CallgetAllShipments(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setShipments(response.shipments);
        });
    }  

    function onClickTable (e) {
        log.debug("clicked : " + e.currentTarget.id);
        shipmentSelected(shipments.find(({id}) => id === e.currentTarget.id));
    }

    // UseMemo to optimize the rendering of table rows
    const renderedRows = useMemo(() => {
        if (!shipments || shipments.length === 0) {
            return <tr><td colSpan={columns.length}>No shipments available</td></tr>;
        }
    
        return shipments.map((shipment) => {
            const name = shipment.customer?.firstname + " " + shipment.customer?.lastname;
            return (
                <tr key={shipment.id}>
                    <TableRowCell first="true" value={shipment.registrationDate} onClick={onClickTable} id={shipment.id} />
                    <TableRowCell value={shipment.invoice?.totalAmount} />
                    <TableRowCell value={shipment.consignor?.name} />
                    <TableRowCell label={GetLabelFromList(resources.shipmentStates, shipment.state)} />
                </tr>
            );
        });
    }, [shipments, onClickTable]);

    const displayData = () => renderedRows;

    return (
        <div className="ShowShipments">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.shipments"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}