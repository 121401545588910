import React, { useCallback } from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';

ShowCompany.propTypes = {
    name: PropTypes.string,
    companyId: PropTypes.string,
    VATNumber: PropTypes.string,
    header: PropTypes.string,
    updateCompany: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    company: PropTypes.object
};

ShowCompany.defaultProps = {
    editMode: false
};

export default function ShowCompany({
    header,
    updateCompany,
    editMode = false,
    company = {},
    name,
    companyId,
    VATNumber
}) {
    const companyName = company.name || name;
    const companyCompanyId = company.companyId || companyId;
    const companyVATNumber = company.VATNumber || VATNumber;

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        updateCompany({ [name]: value });
    }, [updateCompany]);

    return (
        <div className="CompanyData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput
                    col={6}
                    label="label.companyName"
                    name="name"
                    readOnly={!editMode}
                    value={companyName}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.VATNumber"
                    name="VATNumber"
                    readOnly={!editMode}
                    value={companyVATNumber}
                    onChange={changeHandler}
                    required
                />
                <FormLineInput
                    col={3}
                    label="label.companyId"
                    name="companyId"
                    readOnly={!editMode}
                    value={companyCompanyId}
                    onChange={changeHandler}
                    required
                />
            </div>
        </div>
    );
}