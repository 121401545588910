import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLabelFromList } from '../../GeneralFunc';
import { log } from '../../../Log';

const FormLineSelect = ({
    name,
    value,
    list = [],
    label,
    readOnly = false,
    selectLabel,
    placeholder = 'value',
    onChange,
    updateObject,
    freeList = false,
    col = 12,
    order = false
}) => {
    const { t } = useTranslation();
    const className = `form-floating my-2 col-md-${col}`;

    const transformedList = useMemo(() => 
        freeList ? list : list.map(item => ({ value: item.value, label: t(item.label) }))
    , [list, freeList, t]);

    const sortedList = useMemo(() => 
        order ? [...transformedList].sort((a, b) => a.label.localeCompare(b.label)) : transformedList
    , [order, transformedList]);

    const selectedLabel = useMemo(() => 
        freeList ? null : t(getLabelFromList(list, value))
    , [list, value, freeList, t]);

    const showLabel = useMemo(() => 
        (!value || value === '') ? `${t('label.selectA')} ${t(selectLabel || label)}` : `${t(label)}`
    , [value, label, selectLabel, t]);

    const finalList = useMemo(() => {
        const options = sortedList;
        log.debug("in finalList");
    
        if (!value || value === '') {
//            return [{ value: '', label: `${t('label.selectA')} ${t(selectLabel || label)}` }, ...options];
            return [{ value: "", label: "" , disabled: true }, ...options];
        }
    
        return options;
    }, [value, order, transformedList, t, selectLabel, label]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        onChange?.({ name, value: newValue }) || updateObject?.({ [name]: newValue });
    };

    const renderSelect = () => {
        if (readOnly) 
            return (<input className="form-control" id={name} readOnly name={name} value={selectedLabel} placeholder={placeholder} />);
        
        return (
            <select className="form-select" name={name} id={name} value={value || ""} onChange={handleChange} required>
                {finalList.map(({ value, label, disabled }) => (
                    <option key={value} value={value} disabled={disabled}>{label}</option>
                ))}
            </select>  
        )
    };

    return (
        <div className={`form-floating custom-form-group ${className}`}>
            {renderSelect()}
            <label className="mx-1" htmlFor={name}>
                {showLabel}
            </label>
        </div>
    );
};

FormLineSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    label: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    selectLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    updateObject: PropTypes.func,
    freeList: PropTypes.bool,
    col: PropTypes.number,
    order: PropTypes.bool
  };

export default FormLineSelect;
