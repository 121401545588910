import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import viesTest from '../../resources/test/viesTest.json';

function formatAddress(address) {
    log.debug(address);

    // Trim any leading/trailing newlines, then split by '\n'
    const trimmedAddress = address.trim();
    const [street, postalCity] = trimmedAddress.split('\n');

    // Split the second part into postal code and city
    const [postalCode, ...cityParts] = postalCity.split(' ');
    const city = cityParts.join(' ');  // Join the remaining parts into the city name

    // Return the formatted object
    return {
        street,
        postalCode,
        city
    };
}

export function CallGetCompany (country,vatNr,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallGetCompany");

    const countryCode = country ? country : vatNr.substring(0, 2);
    const vatNumber = country ? vatNr : vatNr.substring(2);
    
    console.log("Country Code:", countryCode);  // Outputs 'BE'
    console.log("VAT Number:", vatNumber);  // Outputs '123456789'

    const body = { 
        countryCode : countryCode,
        vatNumber : vatNumber
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        const response = viesTest.viesResponse2;
        if (response.address) {
            response.officialAddress = formatAddress(response.address);
        }
        responseFunc (response);
    }
    else {
        CallBackend ("Vies",body, response => {
            if (response.address) {
                response.officialAddress = formatAddress(response.address);
            }
            responseFunc(response);
        });
    }
}